import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  LightMode,
  Container,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import * as React from "react"
import ziptiLogo from "../../images/ziptiLogo.svg"
import FacebookLoginWrapper from "../facebook_login_wrapper"

export const LandingPage = () => {
  const headerText = "The world's best experts answering your questions"
  const subtitle =
    "Be the first to have their knowledge and experience accessible with the click of a button."
  const cta = "Join with Facebook"

  return (
    <Box h="150vh" bg="gray.800">
      <Box as="section" color="white" py="4.5rem">
        <Container centerContent>
          <Img alt="Logo" src={ziptiLogo} />
        </Container>
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
          py={{ base: "2rem", md: "3rem" }}
        >
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              fontWeight="extrabold"
              maxW="48rem"
              mx="auto"
              lineHeight="1.2"
              letterSpacing="tight"
            >
              {headerText}
            </Heading>
            <Text
              fontSize="xl"
              mt={{ base: "4", md: "3rem" }}
              maxW="xl"
              mx="auto"
            >
              {subtitle}
            </Text>
          </Box>

          <Stack
            justify="center"
            direction={{ base: "column", md: "row" }}
            mt={{ base: "10", md: "4rem" }}
            mb="20"
            spacing="4"
          >
            <LightMode>
              <FacebookLoginWrapper
                onLogin={() => {
                  navigate("/home")
                }}
              >
                <Button
                  as="a"
                  href="#"
                  size="lg"
                  bg="#4329AE"
                  px="8"
                  fontWeight="bold"
                  fontSize="md"
                >
                  {cta}
                </Button>
              </FacebookLoginWrapper>
            </LightMode>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
